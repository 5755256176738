import { useState, useEffect, useRef } from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import ReactPlayer from 'react-player'
import { useHover } from "@uidotdev/usehooks";
import axios from 'axios'

import Nav from 'react-bootstrap/Nav';
import { Navbar, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Facebook, Instagram,TelephoneFill,Youtube,Linkedin,Tiktok, Whatsapp, Envelope } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Marquee from "react-fast-marquee";
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { isMobile } from 'react-device-detect';
import PhotoAlbum from "react-photo-album";

import logo from './img/logo.png';
import homeCarousel1 from './img/mixhome/mixdemedios1.png' 
import homeCarousel2 from './img/mixhome/mixdemedios2.png' 
import homeCarousel3 from './img/mixhome/mixdemedios3.png' 
import homeCarousel4 from './img/mixhome/mixdemedios4.png' 
import homeCarousel5 from './img/mixhome/mixdemedios5.png' 
import titlePath from './img/title-path.png'
import usBanner from './img/us-banner.png'
import star from './img/star.png'
import dooh from './img/dooh.png'
import pop from './img/pop.png'
import btl from './img/btl.png'
import cines from './img/cines.png'
import publicidad from './img/publicidad.png'
import mebs from './img/MEBS.png'

import carls from './img/clientes/carls.png'
import suzuki from './img/clientes/suzuki.png'
import sanofi from './img/clientes/sanofi.png'
import pilgrims from './img/clientes/pilgrims.png'
import premium from './img/clientes/premium.png'
import carso from './img/clientes/carso.png'
import lumbre from './img/clientes/lumbre.png'
import javier from './img/clientes/javier.png'

import contactoImg from './img/contact.png'
import mixwhats from './img/mixwhats.jpg'

import './App.css';
import 'animate.css';

function App() {
  const [goingUp, setGoingUp] = useState(false)
  const prevScrollY = useRef(0)

  const [isLoading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [comments, setComments] = useState("")

  const [showMessage, setShowMessage] = useState(false)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')

  const [ showVideo, setShowVideo ] = useState(false)

  const refHome = useRef()
  const refUs = useRef()
  const refBook = useRef()
  const refServices = useRef()
  const refProjects = useRef()
  const refContact = useRef()

  const [show, setShow] = useState(false);

  const MIX_HOME = [ homeCarousel1, homeCarousel2, homeCarousel3, homeCarousel4, homeCarousel5 ]

  useEffect(() => {
    if( window.location.pathname == '/gracias' ) {
      setLoading(false)
      setTitle("Mensaje enviado")
      setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
      handleShow()
    }
  })

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6LekY2ApAAAAAOM0-Vy12cYrKRiFWZxvlU_JWz4B`, function () {
      console.log("Script loaded!");
    });
  }, []);

  useEffect(() => {

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      var prevScrollYTemp = prevScrollY.current;
      
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;

      if(currentScrollY > 10) {
        document.getElementById("mg-navbar").className = "bg-header navbar navbar-expand-lg navbar-light fixed-top";
      } else {
        document.getElementById("mg-navbar").className = "bg-header-start navbar navbar-expand-lg navbar-light fixed-top";
      }

      /*if (prevScrollYTemp < currentScrollY) {
        if ( !document.getElementById("mg-navbar").classList.contains('navbar-hidden') ) {
          document.getElementById("mg-navbar").classList.remove("navbar-show");
          document.getElementById("mg-navbar").classList.add("navbar-hidden");
        }
      } else if (prevScrollYTemp > currentScrollY) {
        if ( !document.getElementById("mg-navbar").classList.contains('navbar-show') ) {
          document.getElementById("mg-navbar").classList.add("navbar-show");
          document.getElementById("mg-navbar").classList.remove("navbar-hidden");
        }
      }*/
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  const handleClick = (eventKey) => {

    var element = eval(eventKey).current;
    var headerOffset = 90;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });

    /*eval(eventKey).current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })*/

  };
  const [ serviceIndex, setServiceIndex ] = useState(-1)
  const [ serviceSelected, setServiceSelected ] = useState("hoveringMarketing")
  const handleOnClick = (e) => {
    console.log(e);
    //setServiceIndex(e);
  }

  const handleShow = () => setShowMessage(true);
  const handleOnSubmit = (event) => {
    event.preventDefault();
    
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute("6LekY2ApAAAAAOM0-Vy12cYrKRiFWZxvlU_JWz4B", { action: 'submit' }).then(token => {
        //if(isVerified){
        setLoading(true);
        //const recaptchaValue = recaptchaRef.current.getValue();
        var form = document.getElementById("contactForm");
        var formData = new FormData(form);
        formData.append('recaptcha_response', token);

        setLoading(true);
        axios.post("https://inmobiplace.com/api/contactMix/", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
          }).then(function(response) {

            if(response.data == "FAIL" || response.data == "ERROR") {
              setLoading(false)
              setTitle("Error")
              setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
              handleShow()
            } else {
              window.location.href = "/gracias"
              /*setLoading(false)
              setTitle("Mensaje enviado")
              setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
              handleShow()

              setName("");
              setPhone("");
              setEmail("");
              setComments("");*/
            }

          }).catch(function(err) {
            setLoading(false)
            setTitle("Error")
            setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
            handleShow()
          });
      //} else 
        //alert("Por favor, verifica que no eres un robot")
      });
    });
  }

  const closeModalSent = () => {
    if( title == 'Error') setShowMessage(false)
    else window.location.href = "/"
  }

  return (
    <>

      <Modal show={showMessage} onHide={ () => closeModalSent() }>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={ () => closeModalSent() }>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showVideo} onHide={ () => setShowVideo( false ) }>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>
          <div>
            <ReactPlayer url='cines.mp4' playing={ true } loop={ true } muted={ true } controls={ true } className="player-ad-style"/>
          </div>
        </Modal.Body>
      </Modal>

      <div className="App">
        <FloatingWhatsApp 
          phoneNumber="5215545353564"
          accountName="Mix de Medios"  
          className="wa-style"
          statusMessage="En linea"
          avatar={ mixwhats }
          chatMessage="Hola, ¡Bienvenido a Mix de Medios!. ¿Cómo podemos ayudarte?"
          allowClickAway
          notification={false}
        />
          <header className='header'>
        <Container >
          <Navbar id="mg-navbar" collapseOnSelect expand="lg" className={ "bg-header-start" } variant="light" fixed="top">
            <Container>
              <Navbar.Brand href="/">
                <img src={logo} height="60" className="d-inline-block" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                
                <Nav className="me-auto"></Nav>
                
                <Nav onSelect={ handleClick } className='align-items-center'>
                  <Nav.Link eventKey="refUs" className="nav-link-comp font-heder">
                    Nosotros
                  </Nav.Link>
                  {/* <Nav.Link eventKey="refBook" className="nav-link font-heder">
                    Book  
                  </Nav.Link>*/}
                  <Nav.Link eventKey="refProjects" className="nav-link font-heder">
                    Servicios
                  </Nav.Link>
                  <Nav.Link eventKey="refServices" className="nav-link font-heder">
                    Marcas
                  </Nav.Link>
                  <Nav.Link eventKey="refContact" className="nav-link font-heder">
                    Contacto
                  </Nav.Link>
                  
                </Nav>

              </Navbar.Collapse>
              
            </Container>
          </Navbar>
        </Container>
        </header>

        <div style={{ height: '90px' }}></div>
        
        <div ref={refHome}>
          {/* <ReactPlayer className="player-v" url='af.mp4' playing={ true } loop={ true } volume={ 0 } muted={ true } controls={ true } /> */}
          {/* <img src={video}/> */}
          <Carousel>
            { MIX_HOME.map( item => (
              <Carousel.Item>
                <img src={ item } className='footer-gif' />
              </Carousel.Item>
            )) }
          </Carousel>
        </div>
        <div className='banner-us' ref={refUs}>
          <Container>
            <Row className='align-items-center'>
              <Col md={ 5 } xs={ 12 }>
                <div className='small-titles-us'>
                  <span> NOSOTROS </span>&nbsp;&nbsp;&nbsp;
                  <img src={ titlePath } style={{ width: '25px' }}/>
                </div>
                <br/>
                <div className='titles-us'>HACEMOS QUE TU MARCA SEA EL PROTAGONISTA</div>
                <br/>
                <div style={{ color: "#6B6867", fontSize: "15px" }}>No se trata solo de publicidad; es el arte de hacer que tu marca sea el foco, el centro de atención. Se trata de asegurar que tu identidad resuene y permanezca. Transformarla en un mensaje que forme parte de la vida de tu consumidor. Convertiremos cada campaña en una indiscutible experiencia dentro del mercado.</div>
                <br/>
              </Col>
              <Col md={ 7 }xs={ 12 } className='description-us'>
                <img src={ usBanner } className='footer-gif'/>
              </Col>
            </Row>
          </Container>
        </div>


        <div className='section' style={{background: '#F8F7F7' }}>
          <Container align='center'>
            <Row>
              <Col>
                <div className='titles-benefits'>BENEFICIOS</div>
                <br/>
                <br/>
              </Col>
            </Row>
            <Row className='benefit-row'>
              <Col md={ 6 } className='bene-cols'>
                <Container className='benefits-container'>
                  <Row className='align-items-center'>
                    <Col md={ 'auto' }>
                      <img src={ star } width={ 60 } />
                    </Col>
                    <Col>
                      <div className='benefits-card-title'>PUBLICIDAD EN CINE | 74% DE RETENCIÓN</div>
                      <div className='benefits-card-desc'>¡Capta la atención total de tu público objetivo inmerso en la experiencia!</div>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col md={ 6 } className='bene-cols'>
                <Container className='benefits-container'>
                  <Row className='align-items-center'>
                    <Col md={ 'auto' }>
                      <img src={ star } width={ 60 } />
                    </Col>
                    <Col>
                      <div className='benefits-card-title'>POP | VENTAS CON POTENCIA</div>
                      <div className='benefits-card-desc'>Impulsa tus ventas dentro de un entorno altamente interactivo y fomenta la retención de tu marca.</div>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col md={ 6 } className='bene-cols'>
                <Container className='benefits-container'>
                  <Row className='align-items-center'>
                    <Col md={ 'auto' }>
                      <img src={ star } width={ 60 } />
                    </Col>
                    <Col>
                      <div className='benefits-card-title'>BTL | COMPRAS PERSUASIVAS</div>
                      <div className='benefits-card-desc'>Enfoca tácticas más directas y personalizadas que influyen en la decisión de compra.</div>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col md={ 6 } className='bene-cols'>
                <Container className='benefits-container'>
                  <Row className='align-items-center'>
                    <Col md={ 'auto' }>
                      <img src={ star } width={ 60 } />
                    </Col>
                    <Col>
                      <div className='benefits-card-title'>PANTALLAS BBVA | ANUNCIOS CERTEROS</div>
                      <div className='benefits-card-desc'>Transmite tu mensaje justo a tu audiencia según la demografía y refuerza tu identidad.</div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </div>



        <div className='section' ref={ refProjects } style={{background: '#000' }}>
          <br/>
          <Container align='center'>
            <Row>
              <Col>
                <div className='home-white-proyect'>SERVICIOS</div>
                <br/>
                <br/>
              </Col>
            </Row>
            <Row className='benefit-row'>
              <Col md={ 6 } xs={ 12 }className='col-md-6-custom'>
                <OverlayTrigger placement={'bottom'}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      <div className='tooltip-desc'>
                        <ul>
                          <li> +1,250 sucursales </li>
                          <li> +20,000 asistentes mensuales por sucursal </li>
                        </ul>
                      </div>
                    </Tooltip>
                  }
                >
                  <Container className='service-container'>
                    <Row className='align-items-center'>
                      <Col>
                        <div className='service-title'>Publicidad en Pantallas TOMA TURNO </div>
                      </Col>
                    </Row>
                  </Container>
                </OverlayTrigger>
              </Col>
              <Col md={ 6 } xs={ 12 } className='col-md-6-custom'>
                <OverlayTrigger placement={'bottom'}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      <div className='tooltip-desc'>
                        <ul>
                          <li> +770 complejos </li>
                          <li> +6,700 salas </li>
                          <li> Spots de 60 seg </li>
                          <li> Activaciones en lobby </li>
                          <li>Cinemex y Cinepolis</li>
                        </ul>
                      </div>
                    </Tooltip>
                  }
                >
                  <Container className='service-container'>
                    <Row className='align-items-center'>
                      <Col>
                        <div className='service-title'>Publicidad en cines <div onClick={ () => setShowVideo( true )}>(video)</div></div>
                      </Col>
                    </Row>
                  </Container>
                </OverlayTrigger>
              </Col>
              <Col md={ 6 } xs={ 12 } className='col-md-6-custom'>
                <OverlayTrigger placement={'bottom'}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      <div className='tooltip-desc'>
                        <ul>
                          <li> Volumétricos </li>
                          <li> Stands </li>
                          <li> Exhibidores </li>
                          <li> Eventos y exposiciones </li>
                          <li> Publicidad exterior </li>
                          <li> Mobiliario </li>
                          <li> Moldes </li>
                          <li> Impresión </li>
                        </ul>
                      </div>
                    </Tooltip>
                  }
                >
                  <Container className='service-container'>
                    <Row className='align-items-center'>
                      <Col>
                        <div className='service-title'>Pop</div>
                      </Col>
                    </Row>
                  </Container>
                </OverlayTrigger>
              </Col>
              <Col md={ 6 } xs={ 12 } className='col-md-6-custom'>
                <OverlayTrigger placement={'bottom'}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      <div className='tooltip-desc'>
                        <ul>
                          <li> Punto de venta </li>
                          <li> Street marketing </li>
                          <li> Distribución casa por casa </li>
                          <li> Vallas publicitarias </li>
                          <li> Dron Show </li>
                          <li> Staff </li>
                        </ul>
                      </div>
                    </Tooltip>
                  }
                >
                  <Container className='service-container'>
                    <Row className='align-items-center'>
                      <Col>
                        <div className='service-title'>BTL</div>
                      </Col>
                    </Row>
                  </Container>
                </OverlayTrigger>
              </Col>
            </Row>
            <Row>
              <Col md={ { span: 6, offset: 3 } } xs={ 12 } className='col-md-6-custom'>
                <OverlayTrigger placement={'bottom'}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      <div className='tooltip-desc'>
                        <ul>
                          <li> Billboard </li>
                          <li> Reloj </li>
                          <li> Vallas </li>
                          <li> Kioskos </li>
                        </ul>
                      </div>
                    </Tooltip>
                  }
                >
                  <Container className='service-container'>
                    <Row className='align-items-center'>
                      <Col>
                        <div className='service-title'>DOOH</div>
                      </Col>
                    </Row>
                  </Container>
                </OverlayTrigger>
              </Col>
            </Row>
          </Container>
          
        </div>

        <div className='section' style={{background: '#fff' }}>
          <Container>
            <Row>
              <Col align="center">
                <Carousel indicators={ false } centered interval={ 7000 }>
                  <Carousel.Item onClick={ () => setShowVideo( true )} style={{ cursor: 'pointer' }}>
                    <img src={ publicidad } className='products-carousel' />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={ cines } className='products-carousel' />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={ btl } className='products-carousel' />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={ pop } className='products-carousel' />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={ dooh } className='products-carousel' />
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Row>
          </Container>
        
        </div>

        <div ref={ refServices } className='section' style={{background: '#000' }}>
          <Container>
            <Row>
              <Col>
                <div className='home-white-proyect'>MARCAS</div>
                <br/>
                <br/>
              </Col>
            </Row>
            <Row align='center'>
              <Col md={ 4 } xs={ 6 } className='clients-padding'>
                  <img src={ carls } className='img-clients' />
              </Col>
              <Col md={ 4 } xs={ 6 } className='clients-padding'>
                  <img src={ suzuki } className='img-clients' />
              </Col>
              <Col md={ 4 } xs={ 6 } className='clients-padding'>
                  <img src={ carso } className='img-clients' />
              </Col>
              <Col md={ 4 } xs={ 6 } className='clients-padding'>
                  <img src={ lumbre } className='img-clients' />
              </Col>
              <Col md={ 4 } xs={ 6 } className='clients-padding'>
                  <img src={ sanofi } className='img-clients' />
              </Col>
              <Col md={ 4 } xs={ 6 } className='clients-padding'>
                  <img src={ pilgrims } className='img-clients' />
              </Col>
              <Col md={ 4 } xs={ 6 } className='clients-padding'>
                  <img src={ javier } className='img-clients' />
              </Col>
              <Col md={ 4 } xs={ 6 } className='clients-padding'>
                  <img src={ premium } className='img-clients' />
              </Col>
              {/*<Col md={ { span: 6, offset: 3 } } xs={ 12 } className='clients-padding'>
                  <img src={ premium } className='img-clients' />
                </Col>*/}
            </Row>
          </Container>
        </div>

        <div ref={refContact} className='footer section' style={{ paddingTop: 0 }}>
        <div>
          <Container>
            <Row>
                <Col md={6}> 
                  
              </Col>
            </Row>
            <Row className='align-items-center'>
              <Col md={ 6 } xs={ 12 } className='footer-text-desc'>
                <br/>
                <div>
                  <img src={ contactoImg } className='footer-gif'/>
                  {/* <ReactPlayer className="player-c" url='gif.mp4' playing={ true } loop={ true } volume={ 0 } muted={ true } controls={ false } /> */}
                </div>
              </Col>
              <Col md={ 6 } xs={ 12 }>
                <Form id="contactForm" onSubmit={ handleOnSubmit }>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label></Form.Label>
                      <Form.Control required type="text" className='form-footer' placeholder="Nombre:" name='name' value={ name } onChange={ () => setName()}/><br/>
                      <Form.Control required type="email" className='form-footer' placeholder="Email:" name='email' value={ email } onChange={ () => setEmail()}/><br/>
                      <Form.Control required type="number" className='form-footer' placeholder="Teléfono:" name='phone' value={ phone } onChange={ () => setPhone()}/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Control required as="textarea" className='form-footer' placeholder="Mensaje:" rows={3} name='comments' value={ comments } onChange={ () => setComments()}/>
                  </Form.Group>
                    <div align="left"><Button disabled={isLoading} className="button-footer" type="submit" >{isLoading ? 'Enviando…' : 'ENVIAR'}</Button></div>
                    <br />
                </Form>
              </Col>
            </Row>
            <br/>
            <br/>
            <Row className='align-items-center' align="center">
              <Col xs={ 12 } md={ 4 } className='footer-text-desc'>
                <div>Salvador Díaz</div>
                <div><a href='tel:5539395063' target='_blank' > <TelephoneFill /> &nbsp;&nbsp;55 3939 5063 </a></div>
                <div><a href='mailto:sdiaz@mixdemedios.com.mx' target='_blank' > <Envelope /> &nbsp;&nbsp; sdiaz@mixdemedios.com.mx </a></div>
              </Col>
              <Col xs={ 12 } md={ 4 } className='footer-text-desc'>
                <div>Christian González</div>
                <div><a href='tel:5571078504' target='_blank' > <TelephoneFill /> &nbsp;&nbsp;55 7107 8504 </a></div>
                <div><a href='mailto:cgonzalez@mixdemedios.com.mx' target='_blank' > <Envelope /> &nbsp;&nbsp; cgonzalez@mixdemedios.com.mx </a></div>
              </Col>
              <Col xs={ 12 } md={ 4 } className='footer-text-desc'>SIGUENOS EN : 
                    {isMobile && 
                      <br/>
                    }
                    <a href="https://www.instagram.com/mix_de_medios_?igsh=dmVjemdwOTk0NHdq" target='_blank'><Instagram className='social-media'></Instagram></a>
                    <a href="https://www.facebook.com/mixdemediostumarca?mibextid=ZbWKwL" target='_blank'><Facebook className='social-media'></Facebook></a>
              </Col>
            </Row>
            <br/>
            <Row className='line-footer'>
            </Row>
            <br/>
            <Row className='align-items-center' align="center">
                <Col md={2} onClick={ () => handleClick('refHome') } style={{ cursor: 'pointer' }}><img src={logo} width={90 }/></Col>
              <Col md={8} className='footer-text-desc' style={{ cursor: 'pointer' }} onClick={ () => setShow(true) }>
                <br/>
                <div>Aviso de privacidad</div>
                </Col>
              <Col md={2}> 
              </Col>
            </Row>
            
          </Container>
        </div>

      </div>
    </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="footer-privacity">
          <br/>
          <p>GRUPO MEBS, S.A. DE C.V., (en lo sucesivo MEBS), con domicilio en Jos&eacute; Guadalupe Posada n&uacute;mero 16, colonia Ciudad Sat&eacute;lite, Naucalpan de Ju&aacute;rez, C.P. 53100, Estado de M&eacute;xico es el responsable del uso y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:&nbsp;</p>

            <p>&nbsp;</p>

            <p>Los datos personales que en su caso nos proporcione, los utilizaremos para las siguientes finalidades:&nbsp;</p>

            <p>&nbsp;</p>

            <ol>
              <li>Proveerle los servicios o productos que comercializamos.</li>
              <li>Brindarle los servicios que nos solicite.</li>
              <li>Conocer sus necesidades de productos o servicios para estar en posibilidad de ofrecerle los que m&aacute;s se adecuen a sus preferencias.</li>
              <li>Enviar por cualquier medio f&iacute;sico o electr&oacute;nico (correos electr&oacute;nicos, mensajes de texto, redes sociales, mensajer&iacute;a instant&aacute;nea, etc.), conocido o por conocerse, informaci&oacute;n de car&aacute;cter comercial, de mercadeo y promocional sobre los servicios o promociones desarrolladas por MEBS, sus empresas filiales, subsidirias y/o relacionadas, as&iacute; como de sus socios comerciales y/o proveedores.</li>
              <li>Identificaci&oacute;n y contacto.</li>
              <li>Fines estad&iacute;sticos.</li>
              <li>Elaboraci&oacute;n de bases de datos.</li>
              <li>Atender quejas y aclaraciones, y en su caso, tratarlos para fines compatibles con los mencionados en este Aviso de privacidad y que se consideren an&aacute;logos para efectos legales.</li>
            </ol>

            <p>&nbsp;</p>

            <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, podremos solicitarle los siguientes datos personales:</p>

            <ol>
              <li>Nombre.</li>
              <li>Tel&eacute;fono.</li>
              <li>Correo electr&oacute;nico.</li>
            </ol>

            <p>MEBS no recaba Datos Personales Sensibles. No obstante, si se diera el caso, se obtendr&aacute; el consentimiento expreso y por escrito del titular para su tratamiento, a trav&eacute;s de su firma aut&oacute;grafa, firma electr&oacute;nica o cualquier mecanismo de autenticaci&oacute;n.</p>

            <p>&nbsp;</p>

            <p>Le informamos que sus datos personales pueden ser compartidos dentro y fuera del pa&iacute;s para las finalidades antes mencionadas, &uacute;nicamente con empresas filiales, subsidirias y/o relacionadas con MEBS.</p>

            <p>&nbsp;</p>

            <p>Usted acepta y reconoce que las transferencias anteriormente descritas son necesarias para cumplir con las obligaciones que tiene pactadas MEBS con usted por lo que no se requiere su consentimiento para realizar las mismas.</p>

            <p>&nbsp;</p>

            <p>Asimismo, le informamos que para el desarrollo de algunos servicios, productos y/o promociones MEBS podr&aacute; requerir la participaci&oacute;n de socios comerciales y/o proveedores a los cuales se les podr&aacute; dar acceso a nuestras bases de datos para finalidades especificas, en el entendido que en todo momento MEBS conservar&aacute; la propiedad de dichas bases de datos.</p>

            <p>&nbsp;</p>

            <p>Usted tiene derecho a conocer que datos personales tenemos de usted, para que los utilizamos y las condiciones del uso que les damos (Acceso).&nbsp;</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que estar desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no este siendo utilizada adecuadamente (Cancelaci&oacute;n) as&iacute; como oponerse al uso de sus datos personales para fines especif&iacute;cos (Oposici&oacute;n). Estos derechos se conocen como Derechos ARCO.</p>

            <p>&nbsp;</p>

            <p>Para revocar su consentimiento para el tratamiento de sus datos personales y el ejercicio de sus Derechos ARCO, deber&aacute; presentar una solicitud (la &ldquo;Solicitud ARCO&rdquo;), la cual deber&aacute; enviar al correo electr&oacute;nico contacto@grupomebs.com, acompa&ntilde;ada de la siguiente informaci&oacute;n y documentaci&oacute;n:</p>

            <p>&nbsp;</p>

            <ul>
              <li>Su nombre, domicilio y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud ARCO.</li>
              <li>Los documentos que acrediten su identidad (copia de IFE, pasaporte o cualquier otra identificaci&oacute;n oficial) o en su caso,los documentos que acrediten su representaci&oacute;n legal.</li>
              <li>Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca ejercer alguno de los Derechos ARCO.</li>
              <li>Cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales.</li>
              <li>En caso de solicitar una rectificaci&oacute;n de datos, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar ladocumentaci&oacute;n que sustente su petici&oacute;n, y la indicaci&oacute;n del lugar donde se podr&aacute;n revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</li>
            </ul>

            <p>&nbsp;</p>

            <p>MEBS responder&aacute; su Solicitud ARCO y expresar&aacute;&nbsp; los motivos de su decisi&oacute;n mediante un correo electr&oacute;nico en un plazo m&aacute;ximo de 20 d&iacute;as naturales contados desde el d&iacute;a en que se haya recibido su Solicitud ARCO.&nbsp;</p>

            <p>&nbsp;</p>

            <p>En caso de que la Solicitud ARCO se conteste de manera afirmativa o procedente, los cambios solicitados se har&aacute;n en un plazo m&aacute;ximo de 15 d&iacute;as naturales.&nbsp;</p>

            <p>&nbsp;</p>

            <p>Los plazos antes referidos se podr&aacute;n prorrogar por una sola vez por un periodo igual en caso de ser necesario.</p>

            <p>&nbsp;</p>

            <p>MEBS podr&aacute; negar el acceso para que usted ejerza los Derechos ARCO en los siguientes supuestos:</p>

            <p>&nbsp;</p>

            <ul>
              <li>Cuando Usted no sea el titular de los datos personales, o no pueda acreditar la representaci&oacute;n del titular.</li>
              <li>Cuando sus datos personales no obren en las bases de datos de MEBS.</li>
              <li>Cuando se lesionen los derechos de un tercero.</li>
              <li>Cuando exista un impedimento legal o la resoluci&oacute;n de una autoridad competente, que restrinja sus Derechos ARCO, y cuando la rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n haya sido previamente realizada.</li>
            </ul>

            <p>&nbsp;</p>

            <p>La negativa podr&aacute; ser parcial, en cuyo caso MEBS efectuar&aacute; el acceso, rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n en la parte procedente.</p>

            <p>En el caso de revocar el consentimiento que&nbsp; nos haya otorgado para el tratamiento de sus datos personales es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando servicios.</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>Con el objetivo de mejorar la experiencia de sus usuarios en sus sitios web, MEBS podr&aacute; utilizar &ldquo;cookies&rdquo; y/o &ldquo;web beacons&rdquo;. Las &quot;cookies&quot; son archivos de texto que son descargados autom&aacute;ticamente y almacenados en el disco duro del equipo de c&oacute;mputo del usuario al navegar en una p&aacute;gina de Internet para recordar algunos datos sobre este usuario, entre ellos, sus preferencias para la visualizaci&oacute;n de las p&aacute;ginas en ese servidor, nombre y contrase&ntilde;a. Por su parte, las &quot;web beacons&quot; son im&aacute;genes insertadas en un p&aacute;gina de Internet o correo electr&oacute;nico, que puede ser utilizado para monitorear el comportamiento de un visitante, como almacenar informaci&oacute;n sobre la direcci&oacute;n IP del usuario, duraci&oacute;n del tiempo de interacci&oacute;n en dicha p&aacute;gina y el tipo de navegador utilizado, entre otros. Le informamos que utilizamos &quot;cookies&quot; y &quot;web beacons&quot; con fines &uacute;nicamente de marketing, experiencia de usuario y medici&oacute;n, para obtener informaci&oacute;n como la siguiente:</p>

            <p>&nbsp;</p>

            <ul>
              <li>Su tipo de navegador y sistema operativo;</li>
              <li>Las p&aacute;ginas de Internet que visita;</li>
              <li>Los v&iacute;nculos que sigue;</li>
              <li>La direcci&oacute;n IP; y el sitio que visit&oacute; antes de entrar al nuestro.</li>
            </ul>

            <p>&nbsp;</p>

            <p>Estas cookies y otras tecnolog&iacute;as pueden ser deshabilitadas. Para conocer c&oacute;mo hacerlo, consulte el men&uacute; de ayuda de su navegador. Tenga en cuenta que, en caso de desactivar las &quot;cookies&quot;, es posible que no pueda acceder a ciertas funciones personalizadas en nuestro sitio web.</p>

            <p>&nbsp;</p>

            <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras praticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>

            <p>&nbsp;</p>

            <p>El sitio de internet https://grupomebs.com/ puede contener enlaces a otros sitios web, respecto de los cuales MEBS no se hace responsable de las pr&aacute;cticas y usos de los otros sitios, incluyendo los de nuestros patrocinadores y/o socios comerciales</p>

            <p>&nbsp;</p>

            <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s de nuestro sitio web https://grupomebs.com/.</p>

            <p>&nbsp;</p>

            <p>El Responsable cuenta con medidas de seguridad f&iacute;sicas, administrativas y t&eacute;cnicas para resguardar sus Datos Personales. No obstante, en caso de que ocurra alguna vulneraci&oacute;n a la seguridad que afecte de forma significativa sus derechos patrimoniales o morales, el Responsable lo har&aacute; de su conocimiento mediante el correo electr&oacute;nico que proporcion&oacute;, a fin de que usted pueda tomar las medidas necesarias correspondientes para la defensa de los mismos.</p>

            <p>&nbsp;</p>

            <p>En el momento que usted se registra en nuestro portal o nos proporciona sus datos personales usted manifiesta su consentimiento expreso para el tratamiento de sus datos personales de conformidad con este Aviso de Privacidad.</p>

        </Modal.Body>
      </Modal>

    </>
  );
}

export default App;
